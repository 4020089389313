@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Cute+Font|Karla');

body {
  font-family: 'Karla', sans-serif;
}

.workspace-container {
  margin: 40px 0 40px 0;
  max-width: 800px;
}

.palette-container {
  background: white;
  margin-top: 20px;
  max-width: 424px;
  box-shadow: 4px 3px 9px 1px #42424269;
  padding: 10px;
}

.image-container {
  text-align: center;
  margin-top: 20px;
  max-width: 424px;
  max-height: 595px;
}

.image-container img {
  box-shadow: 4px 3px 9px 1px #42424269;
}

h1 {
  text-align: center;
  font-size: 50px;
  margin-top: 20px;
}

.about {
  max-width: 600px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 10px;
  text-align: justify;
}

.social-buttons {
  text-align: center;
  margin-top: 20px;
}

.social-buttons a {
  margin: 10px;
}